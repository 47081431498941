import React, { useState } from 'react';
import './App.css';
import data from './data.json';

interface UserConfig {
  name: string;
  color: string;
  selections: Record<string, boolean>;
}

const defaultUserConfig: UserConfig = {
  name: 'Anonymous',
  color: '#000000',
  selections: {},
};

const parseUserConfig = (userConfigJson: string): UserConfig => {
  try {
    const userConfig = JSON.parse(userConfigJson);
    return {
      name: userConfig.name || 'Anonymous',
      color: userConfig.color || '#000000',
      selections: userConfig.selections || {},
    };
  } catch(e) {}

  return {
    name: 'Invalid config',
    color: '#000000',
    selections: {},
  };
};

const ConfigDisplay = ({userConfig}: {userConfig: UserConfig}) => {
  return <span style={{fontWeight: 'bold', color: userConfig.color}}>{userConfig.name} </span>
};

const MatchingUsers = ({bsp, userConfigs}: { bsp: string, userConfigs: UserConfig[]}) => {
  const matchingConfigs = userConfigs.filter((userConfig) => userConfig.selections[bsp]);

  return <div>
    { matchingConfigs.map((matchingConfig) => <div><ConfigDisplay userConfig={matchingConfig} /></div>)}
  </div>;
};

const App = () => {
  const [name, setName] = useState('Anonymous');
  const [color, setColor] = useState('#FFFFFF');
  const [selections, setSelections] = useState<Record<string, boolean>>({});
  const [userConfigs, setUserConfigs] = useState<string[]>([]);

  const setSelected = (bsp: string, value: boolean) => {
    setSelections((previousSelections) => ({
      ...previousSelections,
      [bsp]: value,
    }));
  };

  let allConfigs = userConfigs.map(parseUserConfig);
  allConfigs.push({
    name,
    color,
    selections,
  });

  return (
    <div className="App">
      <label>
        <span>Name: </span>
        <input type="text" value={name} onChange={(event) => setName(event.target.value)} />
      </label> <br />
      <label>
        <span>Color: </span>
        <input type="color" value={color} onChange={(event) => setColor(event.target.value)} />
      </label> <br />
      {
        userConfigs.map((userConfig, userConfigIndex) => <div><ConfigDisplay userConfig={parseUserConfig(userConfig)} /><input type='text' onChange={(event) => setUserConfigs((oldConfigs) => {
          oldConfigs[userConfigIndex] = event.target.value;
          return [...oldConfigs];
        })} />
        <button onClick={() => setUserConfigs((prev) => { prev.splice(userConfigIndex, 1); return [...prev] })}>Delete</button></div>)
      }
      <button onClick={() => setUserConfigs((prev) => [...prev, JSON.stringify({...defaultUserConfig})])}>
        Add User
      </button>
      {
        data.modes.map((mode) => <div>
          <h1>{mode.name}</h1>
          {
            mode.maps.map((map) => <label style={{ borderWidth: '1px', borderStyle: 'solid', overflow: 'hidden', display: 'inline-block' }}>
                <input type="checkbox" onChange={(event) => setSelected(map.bsp, event.target.checked)} />
                <div>{map.name} </div>
                <img alt={map.name} src={`${process.env.PUBLIC_URL}/maps/${map.bsp}.png`} />
                <MatchingUsers bsp={map.bsp} userConfigs={allConfigs} />
            </label>)
          }
        </div>)
      }
      {JSON.stringify({
        name,
        color,
        selections,
      })}
    </div>
  );
}

export default App;
